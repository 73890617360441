import {
    buttonCss,
    contentDiv,
    headingText,
    paraText,
} from "../../styles/main.module.css";
import {averageCss} from "./appartments.module.css";
import * as React from "react";
import bottom1 from "../../images/bottomImage1.jpg";
import bottom2 from "../../images/bottomImage2.jpg";
import bottom3 from "../../images/bottomImage3.jpg";
import bottom4 from "../../images/bottomImage4.jpg";
import {StaticImage} from "gatsby-plugin-image";

const Apartments = () => {
    return (<>

        <div className={'pb-9 sm:pb-16'}>
            <StaticImage
                placeholder="dominantColor"
                width={1500}
                height={600}
                src={'../../images/lagoon-face-1.jpg'}
                alt={'phase2'}/>
        </div>
        <div className={`${contentDiv} container`}>
            <div>
                <h2 className={headingText}>BEAUTIFUL RIVIERA LIFE : INSIDE OUT</h2>
                <p className={paraText}>At Azizi Riviera, you can choose from studios, 1,
                    2, 3 bedroom apartments with beautiful interiors overlooking the Canal and the skyline view of the 3
                    world’s tallest buildings.</p>
            </div>

            <div className={averageCss}>
                <p><strong>Studios </strong>: Average Size – 35 sq.m.</p>
                <p><strong>1 BR</strong> : Average Size – 55 sq.m.</p>
                <p><strong>2 BR</strong> : Average Size – 85 sq.m.</p>
                <p><strong>3 BR</strong> : Average Size – 1100 sq.m.</p>
            </div>

            <div className={'mt-8'}>
                <div className="grid grid-cols-1 md:mb-4 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="text-center">

                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/bottomImage1.jpg'}
                                alt={'bottom1'}/>
                        </div>

                    </div>
                    <div className="text-center">
                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/bottomImage2.jpg'}
                                alt={'bottom2'}/>
                        </div>
                    </div>

                    <div className="text-center">
                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/bottomImage3.jpg'}
                                alt={'bottom3'}/>
                        </div>
                    </div>
                    <div className="text-center">
                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/bottomImage4.jpg'}
                                alt={'bottom4'}/>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div className={`${contentDiv} container`}>
            <div className={'pb-10'}>
                <p><a className={buttonCss} href="#">I Am
                    Interested</a></p>

            </div>
            <div className={'mb-8'}>
                <h2 className={headingText}>RIVIERA LOCATION
                </h2>
            </div>
            <div>
                <a href={'https://g.page/aziziriviera?share'}>
                    <StaticImage
                        className="m-auto"
                        placeholder="dominantColor"
                        src={'../../images/map.png'}
                        alt={'mapImage'}/>
                </a>
            </div>

        </div>

    </>)
}

export default Apartments
