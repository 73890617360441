import {
    contentDiv,
    firstGrid,
    headingText, eminityImageText,
    paraText,
} from "../../styles/main.module.css";
import * as React from "react";
import image9 from "../../images/Canal-Walk.png";
import image10 from "../../images/Boulevard-Walk.png";
import image11 from "../../images/Parkland.png";
import image12 from "../../images/Restaurants.png";
import image13 from "../../images/Running-and-Cycling.png";
import image14 from "../../images/Tracks.png";
import {StaticImage} from "gatsby-plugin-image";


const Eminity = () => {
    return (<>
        <div className={'pb-9 sm:pb-16'}>
            <StaticImage
                placeholder="dominantColor"
                width={1500}
                height={600}
                src={'../../images/image9.jpg'}
                alt={'eminity'}/>
        </div>
        <div className={`${contentDiv} container`}>
            <div>
                <h2 className={headingText}>EVERYTHING YOU WANT IN
                    A RIVIERA LIFESTYLE</h2>
                <p className={paraText}>Amenities at Azizi Riviera are perfect for the
                    French waterfront lifestyle it offers. Nestled by the Canal, you can enjoy lovely walk in the Canal
                    walk
                    as well as the massive retail boulevard and the parkland.</p>
            </div>

            <div className={'sm:my-20 my-10'}>
                <div
                    className={`sm:gap-y-24 w-3/4 m-auto grid grid-cols-2 md:mb-4 md:grid-cols-3 lg:grid-cols-3 gap-4 ${firstGrid}`}>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}>
                            <img src={image9}
                                 alt=""/>
                        </div>
                        <p className={eminityImageText}><span>Canal Walk</span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image10}/></div>
                        <p className={eminityImageText}><span>Boulevard Walk</span></p>

                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image11}/></div>
                        <p className={eminityImageText}><span>Parkland</span></p>

                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image12}/></div>

                        <p className={eminityImageText}><span>Restaurants</span></p>

                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image13}/></div>
                        <p className={eminityImageText}><span>Running & Cycling</span></p>


                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>Tracks</span></p>

                    </div>


                </div>


            </div>

        </div>
    </>)
}

export default Eminity
