import {
    buttonCss,
    contentDiv,
    firstGrid,
    gridText,
    headingText, iconLine,
    minClass,
    minTextClass,
    paraText
} from "../../styles/main.module.css";
import image1 from "../../images/AL-KHAIL-ROAD.png";
import image2 from "../../images/MEYDAN-RACECOURSE.png";
import image3 from "../../images/MEYDAN-ONE-MALL-MEYDAN-TOWER.png";
import image4 from "../../images/FESTIVAL-CITY-IKEA-1.png";
import image5 from "../../images/DOWNTOWN-DUBAI.png";
import image6 from "../../images/SHEIKH-ZAYED-ROAD.png";
import image7 from "../../images/DUBAI-INTERNATIONAL.png";
import image8 from "../../images/THE-PALM-JUMEIRAH.png";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";


const Connectivity = () => {
    return (<>
        <div className={'pb-9 sm:pb-16'}>
            {/*<img alt={'photo'} src={frontView} width={'100%'} height={'100%'}/>*/}
            <StaticImage
                placeholder="dominantColor"
                width={1500}
                height={600}
                src={'../../images/front-view.jpg'}
                alt={'frontView'}/>
        </div>
        <div className={`${contentDiv} container`}>
            <div>
                <h2 className={headingText}>LOCATED CLOSE TO
                    EVERYTHING YOU NEED!</h2>
                <p className={paraText}>Located in Meydan, Azizi Riviera is located
                    close to the massive Meydan One Mall, as well as the The Meydan Hotel, and golf course and
                    equestrian parks.</p>
            </div>
            <div className={'sm:my-20 my-10'}>

                <div className={`grid grid-cols-2  sm:grid-cols-3 md:grid-cols-4 gap-0 sm:gap-y-20 sm:gap-x-12 ${firstGrid}`}>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image1}/></div>
                        <p className={gridText}>AL KHAIL ROAD</p>
                        <p className={minClass}>01</p>
                        <p className={minTextClass}>MIN</p>
                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>
                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image2}/></div>
                        <p className={gridText}>MEYDAN RACECOURSE</p>
                        <p className={minClass}>02</p>
                        <p className={minTextClass}>MIN</p>
                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>
                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image3}/></div>
                        <p className={gridText}>MEYDAN ONE MALL & MEYDAN TOWER</p>
                        <p className={minClass}>03</p>
                        <p className={minTextClass}>MIN</p>
                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>
                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image4}/></div>
                        <p className={gridText}>FESTIVAL CITY & IKEA</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>

                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>

                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image5}/></div>
                        <p className={gridText}>DOWNTOWN DUBAI</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>

                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>

                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image6}/></div>
                        <p className={gridText}>SHEIKH ZAYED ROAD</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>

                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>

                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image7}/></div>
                        <p className={gridText}>DUBAI INTERNATIONAL AIRPORT (DXB)</p>
                        <p className={minClass}>15</p>
                        <p className={minTextClass}>MIN</p>


                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image8}/></div>
                        <p className={gridText}>THE PALM JUMEIRAH</p>
                        <p className={minClass}>22</p>
                        <p className={minTextClass}>MIN</p>

                    </div>


                </div>

                <div className={'pt-14 sm:pt-16'}>
                    <p><a className={buttonCss} href="#">I Am
                        Interested</a></p>

                </div>
            </div>

        </div>
    </>)
}

export default Connectivity
