import {
    bottomUnderLine,
    headerSection,
    mainSection,
    heroText
} from "./heroArea.module.css";
import {
    buttonCss,
    contentDiv,
    headingText,
    paraText,
} from "../../styles/main.module.css";
import FormLayout from "./form";
import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const HeroArea = ({countryISO, short, symbol}) => {
    return (<>

        <div className={mainSection}>
            <section className={`${headerSection} text-center pt-4 bg-left md:bg-center `}>
                <div className={'text-white text-center relative'}>
                    <div className={'my-7 flex justify-center  align-center'}>
                        <StaticImage
                            className="sm:w-54 w-32 h-auto "
                            placeholder="dominantColor"
                            src={'../../svg/Azizi-Riviera-white.svg'}
                            alt={'Azizi'}/>
                    </div>

                    <div className={'pt-0 py-8'}>
                        <span className={`${bottomUnderLine} w-1/4 md:w-20`}>
                        </span>
                    </div>
                    <div>
                        <div className={`mb-5 m-auto  leading-6  text-center  tracking-widest ${heroText}`}>OWN A
                            WATERFRONT HOME
                            IN <br/> MAIDAN MBR CITY DUBAI
                        </div>
                    </div>
                    <div>
                        <p className={'m-auto mb-5 text-xs sm:text-sm md:max-w-xl w-2/3'}>
                            Fully Frunished 7% Guaranteed ROI for Five Years
                        </p>
                    </div>
                    <div>
                        <p className={'m-auto text-xs sm:text-sm tracking-wider md:max-w-xl w-3/4'}>
                            AVAILABLE FROM:
                        </p>
                    </div>
                    <div>
                        <h1 className={'m-auto text-3xl font-bold md:max-w-xl w-3/4'}>
                            {/*AED 620K*/}
                            {symbol} {short}
                            {/*<span className={'text-lg'}>( $168,790 / €142,234)</span>*/}
                        </h1>
                    </div>

                </div>
            </section>


            <FormLayout countryISO={countryISO} showPara={true} heading={`*Special offers available for a limited time*`}
                        buttonName={'Please send me more details'}/>


            <div className={'py-12'}>
                <div className={`${contentDiv} container`}>
                    <h2 className={headingText}>WELCOME TO
                        AZIZI
                        RIVIERA</h2>
                    <p className={paraText}>Come home to the French Riviera of
                        Dubai.
                        This is Azizi Riviera in Meydan, Dubai. With 3
                        different phases, you can choose from studios, 1, 2, 3 BR apartments with views of the Dubai
                        Canal. Come home to the high-end waterfront lifestyle complemented by the best of amenities
                        and more. These are elegant homes with a great retail boulevard clubbed in with a
                        picturesque canal walkway, cycling & jogging track and more. Surrounded by lush greenery and
                        offering modern luxury, move to Azizi Riviera and live the ultimate modern French
                        Mediterranean Riviera Lifestyle in Dubai!</p>
                </div>

            </div>

        </div>
        <div className={'md:max-w-2xl w-11/12 m-auto'}>

            <iframe width="100%" height="350" src="https://youtu.be/o5hku8xLa8Q">
            </iframe>

            {/*<video width="100%" controls>*/}
            {/*    <source src="https://youtu.be/o5hku8xLa8Q"*/}
            {/*            type="video/mp4">*/}
            {/*    </source>*/}
            {/*</video>*/}

        </div>

        <div className={'py-10 text-center'}>
            <p><a className={buttonCss} href="#">I Am
                Interested</a></p>

        </div>

    </>)
}

export default HeroArea
