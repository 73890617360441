import * as React from 'react'
import {} from '../styles/main.module.css'
import NavHeader from '../components/NavHeader'
import HeroArea from '../components/aziziComp/heroArea'
import Connectivity from '../components/aziziComp/connectivities'
import Eminity from '../components/aziziComp/eminity'
import Plans from '../components/aziziComp/plans'
import Apartments from '../components/aziziComp/apartments'
import Footer from '../components/footer'
import { StaticImage } from 'gatsby-plugin-image'
import ModalPage from '../../src/components/modalPage'
import SEO from '../components/App/SEO'
import { siteUrl } from '../components/_utils'
import { useEffect, useState } from 'react'
import { getCountry } from '../action'
import { useScrollTracker } from 'react-scroll-tracker'
import ReactGA from 'react-ga'
import aziziBackground from '../images/aziziBackground.jpg'
const shortNumber = require('short-number')

let dollarAmount = 168790

const Azizi = ({data}) => {

  const [countryISO, setCountryISO] = useState('ae')
  const [short, setShort] = useState(shortNumber(dollarAmount))
  const [symbol, setSymbol] = useState('$')

  useEffect(() => {
    getCountry({amount: dollarAmount}).then((data) => {
      console.log(data)

      if (data.short) {
        setShort(data.short)
      }
      if (data.symbol) {
        setSymbol(data.symbol)
      }
      if (data.iso) {
        setCountryISO(data.iso.toLowerCase())
      }
    })
  }, [])

  /*
   if (typeof window !== 'undefined') {
     useScrollTracker([10, 15, 25, 50, 75, 100], ({scrollY}) => {
       ReactGA.event({
         category: 'scroll',
         action: scrollY,
       })
     })
   }
 */

  const logoComp = (
    <StaticImage
      className="w-14 md:w-28 lg:w-42 align-middle"
      placeholder="tracedSVG"
      src={'../images/azizi-1.png'}
      alt={'Azizi '}
    />
  )
  const titleParams = {
    title: `Azizi-Properties For Sale In Dubai | ${siteUrl}`,
    ogTitle: `Azizi-Properties For Sale In Dubai | ${siteUrl}`,

    description: 'Trying to find commercial & residential properties for sale? Azizi Developments offers modern apartments across Dubai.',
    ogDescription: 'Trying to find commercial & residential properties for sale? Azizi Developments offers modern apartments across Dubai.',
    keywords: 'At Azizi Riviera, you can choose from studios, 1, 2, 3 bedroom apartments with beautiful interiors overlooking the Canal and the skyline view of the 3 world’s tallest buildings.',

    secureImageUrl: aziziBackground,
    ogImage:aziziBackground,
    ogImageAlt: 'Azizi',

    pageLink: `${siteUrl}azizi/`,
    orUrl: `${siteUrl}azizi/`,
    canonicalRef: `${siteUrl}azizi/`,
  }

  return (<>
    <SEO data={titleParams}/>
    <NavHeader logoComp={logoComp}/>
    <HeroArea countryISO={countryISO} short={short} symbol={symbol}/>
    <Connectivity/>
    <Eminity/>
    <Plans/>
    <Apartments/>
    <Footer/>
    <ModalPage/>

  </>)
}

export default Azizi
