import {
    buttonCss,
    contentDiv,
    headingText
} from "../../styles/main.module.css";
import {phasePara, phaseText, riverFront1, phaseCss} from "./plans.module.css";
import * as React from "react";
import phase1 from "../../images/phase1.jpg";
import phase2 from "../../images/phase2.jpg";
import phase3 from "../../images/phase3.jpg";
import paymentImage from "../../images/aziz-river-front.jpg";
import specialImage1 from "../../images/current.png";
import specialImage2 from "../../images/current2.png";
import {StaticImage} from "gatsby-plugin-image";



const Plans = () => {
    return (<div className={phaseCss}>

            <div className={'pb-9 sm:pb-16'}>
                <StaticImage
                    placeholder="dominantColor"
                    width={1500}
                    height={600}
                    src={'../../images/aziz-river-front.jpg'}
                    alt={'photo'}/>
            </div>
            <div className={`${contentDiv} container`}>
                <div>
                    <h2 className={headingText}>PAYMENT PLAN
                    </h2>
                </div>

                <div className={'my-10'}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
                        <div className="text-left">
                            <h1>PHASE 1 (1 - 18)
                            </h1>

                            <p className={'mb-2'}>
                                <a className={phaseText}>
                                    <strong className={'font-medium'}>Handover:</strong> <strong
                                    className={'font-light'}>December
                                    2021</strong>
                                </a>
                            </p>
                            <p className={phasePara}>Starting at AED 744K</p>
                            <p className={phasePara}>Pay 20% During Construction</p>
                            <p className={phasePara}>Pay 80% on Handover</p>

                        </div>
                        <div className="text-center">
                            <StaticImage
                                className="m-auto"
                                placeholder="dominantColor"
                                src={'../../images/phase1.jpg'}
                                alt={'phase1'}/>
                        </div>


                        <div className="hidden sm:block text-center">
                            <StaticImage
                                className="m-auto "
                                placeholder="dominantColor"
                                src={'../../images/phase2.jpg'}
                                alt={'phase2'}/>
                        </div>
                        <div className="hidden sm:block text-right">
                            <h1>PHASE 2 (19 - 35)
                            </h1>

                            <p className={'mb-2'}><a className={phaseText}>
                                <strong className={'font-medium'}>Handover:</strong> <strong className={'font-light'}>December
                                2021</strong>
                            </a></p>
                            <p className={phasePara}>Starting at AED 620K</p>
                            <p className={phasePara}>Pay 30% During Construction</p>
                            <p className={phasePara}>Pay 70% on Handover</p>
                        </div>


                        <div className="sm:hidden text-right">
                            <h1>PHASE 2 (19 - 35)</h1>

                            <p className={'mb-2'}><a className={phaseText}>
                                <strong className={'font-medium'}>Handover:</strong> <strong className={'font-light'}>December
                                2021</strong>

                            </a></p>
                            <p className={phasePara}>Starting at AED 620K</p>
                            <p className={phasePara}>Pay 30% During Construction</p>
                            <p className={phasePara}>Pay 70% on Handover</p>
                        </div>
                        <div className="sm:hidden text-center">
                            <StaticImage
                                className="m-auto "
                                placeholder="dominantColor"
                                src={'../../images/phase2.jpg'}
                                alt={'phase2'}/>
                        </div>


                        <div className="text-left">
                            <h1>PHASE 3 (36 - 48)

                            </h1>

                            <p className={'mb-2'}><a className={phaseText}>
                                <strong className={'font-medium'}>Handover:</strong> <strong className={'font-light'}>March
                                2022</strong>

                            </a></p>
                            <p className={phasePara}>Starting at AED 634K</p>
                            <p className={phasePara}>Pay 40% During Construction</p>
                            <p className={phasePara}>Pay 60% on Handover</p>
                        </div>
                        <div className="text-center">
                            <StaticImage
                                className="m-auto"
                                placeholder="dominantColor"
                                src={'../../images/phase3.jpg'}
                                alt={'phase2'}/>
                        </div>


                    </div>
                </div>

                <div>
                    <h2 className={headingText}>SPECIAL OFFER</h2>


                    <div className={'my-5 hidden m-auto sm:flex justify-around items-center w-2/5'}>
                        <div>
                            <StaticImage
                                className={'m-auto sm:w-20 sm:h-20 w-12 h-12'}
                                placeholder="dominantColor"
                                src={'../../images/current.png'}
                                alt={'photo'}/>
                            <p className={' mt-2 text-xs text-primary-lightGray tracking-widest'}><strong
                                className={'text-primary-gray'}>97%</strong> ROI</p>

                        </div>
                        <div>
                            <StaticImage
                                className={'m-auto sm:w-20 sm:h-20 w-12 h-12'}
                                placeholder="dominantColor"
                                src={'../../images/current2.png'}
                                alt={'photo'}/>
                            <p className={'mt-2 text-xs text-primary-lightGray tracking-widest'}><strong
                                className={'text-primary-gray'}>SPECIAL
                                DISCOUNT</strong><br/>ALSO AVAILABLE</p>
                        </div>
                    </div>
                    <div className={'my-5 sm:hidden w-2/3 m-auto'}>
                        <div className={'flex items-center mb-5'}>
                            <StaticImage
                                className={'w-12 h-12 mr-3'}
                                placeholder="dominantColor"
                                src={'../../images/current.png'}
                                alt={'photo'}/>
                            <p className={' mt-2 text-xs text-primary-lightGray tracking-widest'}><strong
                                className={'text-primary-gray'}>7%</strong> ROI</p>

                        </div>
                        <div className={'flex items-center'}>
                            <StaticImage
                                className={'w-12 h-12 mr-3'}
                                placeholder="dominantColor"
                                src={'../../images/current2.png'}
                                alt={'photo'}/>
                            <p className={'mt-2 text-xs text-primary-lightGray tracking-widest'}><strong
                                className={'text-primary-gray'}>SPECIAL
                                DISCOUNT</strong><br/>ALSO AVAILABLE</p>
                        </div>
                    </div>


                    <div className={'pb-8 pt-8'}>
                        <p><a className={buttonCss} href="#">I Am
                            Interested</a></p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plans
